import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { WrapBackground } from "../common/WrapBackground";
import { HomeArea, HomeAreaColumn } from "./styles/HomeArea";
import { HomeBackground } from "../common/Colors";
import { HomeButton } from "./HomeButton";
import { Profile } from "../../utils/profile";

type Props = RouteComponentProps<{}> & Profile;

type State = {};

class ClientHome extends React.Component<Props, State> {
  render() {
    return (
      <WrapBackground
        history={this.props.history}
        profile={this.props.profile}
        color={HomeBackground}
      >
        <HomeArea>
          <HomeAreaColumn>
            <div style={{ cursor: "pointer" }} onClick={this.onTraining}>
              <HomeButton path="/assets/TrainingApp.png" />
            </div>
            <div style={{ cursor: "pointer" }} onClick={this.onReccomend}>
              <HomeButton path="/assets/ReccomentApp.png" />
            </div>
          </HomeAreaColumn>
          <HomeAreaColumn>
            <Link to="/student/training_test_results">
              <HomeButton path="/assets/TrainingTestResult.png" />
            </Link>
            <Link to="/student/practical_test_results">
              <HomeButton path="/assets/PracticalTestResult.png" />
            </Link>
          </HomeAreaColumn>
        </HomeArea>
      </WrapBackground>
    );
  }

  onTraining() {
    window.open(process.env.REACT_APP_TRAINING_URL);
  }

  onReccomend() {
    window.open(process.env.REACT_APP_RECOMMEND_URL);
  }
}

export default ClientHome;
